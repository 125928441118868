<template>
  <div class="myborrow">
    <van-nav-bar
      :title="$t('MYBORROW')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div v-if="empty" class="empty">
      <van-empty
        class="custom-image"
        :image="emptyImg"
      >
      <div slot="description" >
        <div class="statuttop">{{$t('NOBRORROW')}}！！！</div>
        <!-- <div class="statutbottom">{{$t('CURRENTMONTHNEEDNOTBORRORW')}}</div> -->
      </div>
      </van-empty>
    </div>
    <div v-else v-for="(item, index) in borrowList" :key="index" @click="godetail(item.id)" class="borrowbox">
      <div class="borowTop">
        <van-cell center>
          <div class="title" slot="title">
            {{$t('BORRORSERIALBUNBER')}}:
          </div>
          <div class="showstatus" slot="default">{{$t('SUBMITSUCCEED')}}</div>
          <div slot="label">{{item.id}}</div>
          <van-icon color="#666" size="20" slot="right-icon" name="arrow" />
        </van-cell>
      </div>
      <div class="borowBottom">
        <van-cell center>
          <div slot="icon" class="imgbox">
            <van-image round class="iconimg" :src="imgurlBB" />
          </div>
          <div class="title" slot="title">
            {{$t('TOTAILMONEY')}}：{{Number(item.money).toFixed(2)}}{{$t('UNIT')}}
          </div>
          <div class="fon16" slot="label">
            <div class="borrowTime">{{$t('BORROWINGTIME')}}：{{item.months}}{{$t('MONTHS')}}</div>
            <div class="everyBorrow">{{$t('EVERYISSUE')}}：฿{{item.monthly}}</div>
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { myLoan } from '@/api/my'
import './css/my.css'
export default {
  name: 'myborrow',
  data () {
    return {
      borrowList: null,
      empty: null,
      emptyImg: require('./img/p_01 (1).png'),
      imgurlBB: require('./img/money.jpg')
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    godetail (id) {
      this.$router.push({
        name: 'borrowdetail',
        params: {
          id
        }
      })
    },
    async loaddata () {
      const { data } = await myLoan()
      console.log(data.data.length)
      if (data.data.length === 0) {
        this.empty = true
      } else {
        this.empty = false
        this.borrowList = data.data
      }
      console.log(this.$route)
    }
  },
  created () {
    this.loaddata()
  }
}
</script>

<style scoped>
.borrowbox {
  margin-top: 10px;
}
.borowTop {
  border-bottom: 1px solid #e4e4e4;
}
.showstatus {
  color: red;
}
.imgbox {
  margin-right: 10px;
  box-sizing: border-box;
  padding: 5px;
  width: 60px;
  height: 60px;
  border: 1px solid #e4e4e4;
}
.iconimg {
  width: 100%;
  height: 100%;
}
.fon16 {
  font-size: 14px;
  color: #198eed;
}
</style>
